
import { OrderedListOutlined, UploadOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import axios from 'axios';
import { Vue, Options } from 'vue-class-component';
import infoComponentVue from '../components/info.component.vue';
import { ref } from 'vue';

interface FileItem {
    uid: string;
    name?: string;
    status?: string;
    response?: string;
    url?: string;
    preview?: string;
    originFileObj?: any;
    file: string | Blob;
}

@Options({
    components: {
        'info': infoComponentVue,
        'extension-table-outlined': OrderedListOutlined,
        'upload-outlined': UploadOutlined
    }
})
export default class HealthCheck extends Vue {
    loading = true;
    initialLoading = true;
    fileList = ref<FileItem[]>([]);
    csvFile: any = null;
    csvFileLabel: any = '';
    loadingUpload = false;
    labelCol = {
        span: 10,
    };
    wrapperCol = {
        span: 13
    };
    updatingRow = false;
    upcColumns = [
        {
            title: 'UPC',
            dataIndex: 'upc',
        },
        {
            title: 'Total',
            dataIndex: 'total',
        },
    ];
    upcRows = [];
    columns = [
        {
            title: 'PLU',
            dataIndex: 'plu',
            sorter: (a: any, b: any) => {},
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Company Code',
            dataIndex: 'company_code',
            sorter: (a: any, b: any) => {},
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Store Code',
            dataIndex: 'store_code',
            sorter: (a: any, b: any) => {},
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'SKU',
            dataIndex: 'sku',
            sorter: (a: any, b: any) => {},
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Product ID',
            dataIndex: 'product_id',
            sorter: (a: any, b: any) => {},
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Options',
            dataIndex: 'options',
            slots: {
                customRender: 'viewMore',
            }
        }
    ];
    rows = [];
    loadingTable = true;
    pagination = {
        current: 1,
        pageSize: 25,
        total: 1
    };
    showModal = false;
    viewingRow = null;
    selectedRows: any = [];
    rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            this.selectedRows = selectedRows;
        }
    };
    exportLoading = false;
    sortBy = 'plu';
    sortOrder = 'asc';
    pluNumber = '';
    companyCode = '';
    storeCode = '';
    sku = '';
    productId = '';
    editRow: any = {};
    exportFilteredRowsLoading = false;
    total = '';

    showViewModal(record: any) {
        this.editRow.id = record.id;
        this.editRow.plu = record.plu;
        this.editRow.company_code = record.company_code;
        this.editRow.store_code = record.store_code;
        this.editRow.product_id = record.product_id;
        this.editRow.product_name = record.product_name;
        this.editRow.variant_options = record.variant_options;
        this.editRow.sku = record.sku;
        this.editRow.name_eng = record.name_eng;
        this.editRow.extended_name_eng = record.extended_name_eng;
        this.editRow.brand_eng = record.brand_eng;
        this.editRow.long_description_eng = record.long_description_eng;
        this.editRow.ingredients_eng = record.ingredients_eng;
        this.editRow.category_eng = record.category_eng;
        this.editRow.price_ringgit = record.price_ringgit;
        this.editRow.sale_price_ringgit = record.sale_price_ringgit;
        this.editRow.quantity = record.quantity;
        this.editRow.is_chilled = record.is_chilled;
        this.editRow.is_frozen = record.is_frozen;
        this.editRow.weight = record.weight;
        this.editRow.length = record.length;
        this.editRow.width = record.width;
        this.editRow.height = record.height;
        this.editRow.volume = record.volume;
        this.editRow.is_age_gated = record.is_age_gated;
        this.editRow.non_halal = record.non_halal;
        this.editRow.max_cart_quantity = record.max_cart_quantity;
        this.editRow.unit_count = record.unit_count;
        this.editRow.unit_type = record.unit_type;
        this.editRow.is_perishable = record.is_perishable;
        this.editRow.is_consignment = record.is_consignment;
        this.editRow.product_origin = record.product_origin;
        this.editRow.product_grade = record.product_grade;
        this.editRow.product_size = record.product_size;
        this.editRow.upc = record.upc;
        this.editRow.is_published = record.is_published;
        this.editRow.tax_rate = record.tax_rate;
        this.editRow.filler_1 = record.filler_1;
        this.editRow.filler_2 = record.filler_2;
        this.editRow.filler_3 = record.filler_3;
        this.editRow.filler_4 = record.filler_4;
        this.editRow.filler_5 = record.filler_5;
        this.editRow.start_publish_date = record.start_publish_date;
        this.editRow.end_publish_date = record.end_publish_date;
        this.editRow.preset_publish = record.preset_publish;
        this.editRow.preset_quantity = record.preset_quantity;
        this.editRow.image_links = record.image_links;

        this.showModal = true;
    }

    async updateRow() {
        this.updatingRow = true;
        try {
            const res = await axios.post(`${process.env.VUE_APP_API_URL!}/extension-table/update`, this.editRow, {
                headers: {
                    APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`
                }
            });

            const data = await res.data;
            this.updatingRow = false;
            if (data.error) {
                if (data.error_type == 'validation') {
                    let firstKey: any = null;
                    Object.keys(data.errors).forEach(key => {
                        if (firstKey == null) {
                            firstKey = key;
                        }
                    });
                    message.error(data.errors[firstKey]);
                } else {
                    message.error(data.error_message);
                }
            } else {
                message.success('Successfully updated row!');
            }
        } catch (error) {
            console.log(error);
            this.updatingRow = false;
            message.error('Sorry, something went wrong. Please try again later.');
        }
    }

    handleTableChange(event: any, filters: any, sorter: any) {
        const current = event.current;
        this.pagination.current = current;
        this.sortBy = sorter.field;
        this.sortOrder = sorter.order;
        this.loadingTable = true;
        this.getHealthCheck();
    }

    async deleteRow(row: any) {
        if (confirm('Are you sure you want to delete this record?')) {
            row.deleteLoading = true;
            try {
                const fd = new FormData();
                fd.append('id', row.id);
                const res = await axios.post(`${process.env.VUE_APP_API_URL!}/extension-table/delete`, fd, {
                    headers: {
                        APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`,
                        'Content-Type': 'application/json',
                    }
                });
                const data = res.data;
                row.deleteLoading = false;
                if (data.error) {
                    message.error(data.error_message);
                } else {
                    message.success('Successfully deleted row!');
                    this.getHealthCheck();
                }
            } catch (error) {
                row.deleteLoading = false;
                console.log(error);
                message.error('Sorry, something went wrong. Please check your internet connection and try again later.');
            }
        }
    }

    async getHealthCheck() {
        try {
            this.loadingTable = true;
            let url = `${process.env.VUE_APP_API_URL}/health-check`;

            const res = await axios.get(url, {
                headers: {
                    APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`
                }
            });
            const data = await res.data;
            if (data.error) {
                message.error(data.error_message);
            } else {
                data.health_check_rows.forEach((row: any) => {
                    row.key = row.id;
                    row.deleteLoading = false;
                });
                this.pagination = {
                    current: 1,
                    pageSize: 100,
                    total: data.health_check_rows.length
                };
                this.loadingTable = false;
                this.rows = data.health_check_rows;
                const totalRows = data.health_check_total[0]['COUNT(*)'];
                this.total = ' of ' + totalRows + ' total';
                this.upcRows = data.duplicate_upc_rows;
                this.initialLoading = false;
            }
        } catch (error) {
            console.log(error);
            message.error('Sorry, something went wrong. Please try again later.');
        }
    }

    mounted() {
        console.log('[APP] Health Check Mounted');
        this.getHealthCheck();
    }
}
